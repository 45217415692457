/**
 * 用户行为js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";

const request = axios.request;
/**
 * 获取投诉数据
 * @returns {Promise<unknown>}
 */
export const queryComplaint = (data) => {
  return request.doPost('/userbehavior/queryComplaint', data);
}

/**
 * 获取用户评价数据
 * @returns {Promise<unknown>}
 */
export const queryClientEvaluate = (data) => {
  return request.doPost('/userbehavior/queryClientEvaluate', data);
}

/**
 * 获取智能聊天记录
 * @returns {Promise<unknown>}
 */
export const queryRobotmsgReload = (data) => {
  return request.doPost('/userbehavior/queryRobotmsgReload', data);
}

/**
 * 获取对话记录
 * @returns {Promise<unknown>}
 */
export const queryallRobotByClientid = (data) => {
  return request.doPost('/userbehavior/queryallRobotByClientid', data);
}

