<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.keyword" placeholder="搜索关键字" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <!--<el-select v-model="listQuery.complainttype" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>-->
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="序号" width="50px" align="center" type="index"></el-table-column>
      <el-table-column label="项目编号" width="90px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.projid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="200px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.projname}}</span>
        </template>
      </el-table-column>
      <el-table-column label="咨询用户" width="300px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.clientid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="消息开始时间" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消息结束时间" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.endime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消息条数" width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span><a @click="showRobotMessage(row)">查看(<span style="color: red">{{ row.messageNotCount}}</span>/{{ row.messageCount}})</a></span>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog class="server-container" title="对话消息列表" :visible.sync="dialogFormVisible">
      <div class="task-left" style="height: 100%;position: relative">
        <div style="text-align: center;font-size: 16px;margin-bottom: 10px">项目名称:{{item.projname}}</div>
      <div class="message-record">
        <el-scrollbar ref="selfScrollbar" id="msgList" style="height: 450px;font-size: 12px">
          <template v-for="item in messageConfig.data">
            <div :class="['message-row',{self: item.msgflag==1}]">
              <div>
                <span class="mar-r10" v-if="item.consulttype==2">智能</span>
                <span class="mar-r10" v-if="item.consulttype==1 && item.msgflag==2">人工客服</span>
                <span class="mar-r10" v-if="item.consulttype==1 && item.msgflag==1">人工用户</span>
                <span class="mar-r10" v-if="item.msgflag==2">客服编号:{{item.csid}}</span>
                <span class="mar-r10" v-if="item.consulttype==1 && item.msgflag==2">{{ item.realname }}</span>
                <span class="mar-r10" v-if="item.msgflag==1">IP:{{ item.ip }}</span>
                <span>{{ dateToString(item.createtime) }}</span>
              </div>
              <div class="message-content mar-t10">
                <div  v-html="item.content"></div>
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {queryRobotmsgReload,queryallRobotByClientid} from "@/apis/user-behavior";
  import { parseTime } from '@/utils'
  const statusOptions = [
    { key: '1', display_name: '业务投诉' },
    { key: '2', display_name: '服务态度' },
  ]
  const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'complaint',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      },
      typeFilter(type) {
        return calendarTypeKeyValue[type]
      },
      parseTime:parseTime
      ,ipFilter(ip) {
        if (ip == '127.0.0.1') {
          ip = ip + "(本地)"
        }
        return ip;
      },
    },
    data() {
      return {
        tableKey: 0,
        dialogFormVisible:false,
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 20,
          keyword:'',
          complainttype:'',
          auid:null
        },messageConfig: {
          data: [],
          loadData: () => {
          },
        },conninfo:{

        },item:{
          projname:''
        }
      }
    },updated(){
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.auid=this.user.userId
        queryRobotmsgReload(this.listQuery).then(res => {
        const {data = [], count = 0} = res;
        this.list=data;
        this.total=count;
        this.listLoading = false;
        })
      }, handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },showRobotMessage(row){
        let that=this;
        this.dialogFormVisible=true;
        var data={
          projid:row.projid,
          clientid:row.clientid,
        }
        this.item.projname=row.projname;
        queryallRobotByClientid(data).then(res =>{
          this.messageConfig.data = res.retdata.robotMsgList;
          if (res.retdata.clientInfo){
            this.conninfo = res.retdata.clientInfo;
          }
          that.selfScrollDown();
          this.getList()
        })
      }, selfScrollDown() {
        let that=this;
        setTimeout(function () {
          that.$refs['selfScrollbar'].wrap.scrollTop = that.$refs['selfScrollbar'].wrap.scrollHeight;
          console.log(that.$refs['selfScrollbar'].wrap.scrollTop)
        },100)
      }
    },computed:{
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '业务投诉', value: 1},
        {name: '服务态度', value: 2},
      ]
    },
  }
  }
</script>

<style scoped lang="scss">
  .server-container ::v-deep {
    .task-left {
      width:100%;
      padding: 0;
      $editor-height: 200px;

      .custominfo {
        position: absolute;
        background-color: #f3eded;
        height: 30px;
        width: 100%;
        line-height: 30px;
        color: #999898;
      }

      .message-record {
        height: 100%;
        padding-left: 10px;
        padding-top: 10px;

        .message-row {
          box-sizing: border-box;
          margin-bottom: 15px;

          &.self {
            text-align: right;
            padding-right: 20px;

            .message-content {
              &:before {
                left: 100%;
                border: 5px solid transparent;
                border-left-color: #F8f8f8;
              }
            }
          }

          .message-content {
            box-sizing: border-box;
            padding: 0 10px;
            display: inline-block;
            line-height: 2.5;
            border: 1px #eee solid;
            background-color: #F8f8f8;
            border-radius: 3px;
            position: relative;
            margin-top: 5px;

            &:before {
              box-sizing: border-box;
              content: '';
              top: 10px;
              position: absolute;
              right: 100%;
              border: 6px solid transparent;
              border-right-color: #F8f8f8;
            }
          }
        }
      }
    }
    .el-aside {
      background-color: white;
      color: #333;
      height: 100%;
      line-height: 20px;
      font-size: 15px;
      border-right: 1px solid #d9d8d8;
    }

    .el-aside li a {
      padding: 5px 10px;
      display: flex;
      background-color: white;
      border-bottom: 1px solid #bfbfbf;

    }

    .active {
      color: #ef8709;
    }
  }

  @keyframes fade {
    from {
      opacity: 1.0;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1.0;
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 1.0;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1.0;
    }
  }

  .headerBox {
    animation: fade 600ms infinite;
    -webkit-animation: fade 600ms infinite;
  }
  .filter-item {
    margin-left:5px;
  }

</style>
<style>
  .el-tooltip__popper {
    max-width: 800px;
  }
  .el-table {
    font-size: 12px;
    color: #606266;
  }
  .server-container .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

</style>
